import React, { useEffect } from 'react';
import { sendSupportEmailRequest } from 'src/api/httpClient';

export const Contact = (props: { mapTitle: string | null }) => {
  useEffect(() => {
    const mapElement = document.getElementById('about-map');
    if (mapElement) {
      mapElement.outerHTML = `<iframe class="mb-4 mb-lg-0" title="${
        props.mapTitle || ''
      }" src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d12097.433213460943!2d-74.0062269!3d40.7101282!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xb89d1fe6bc499443!2sDowntown+Conference+Center!5e0!3m2!1smk!2sbg!4v1539943755621" frameborder="0" allowfullscreen="" style="border: 0px; width: 100%; height: 384px;"></iframe>`;
    }
  }, []);

  const sendSupportRequestEmailAction = () => {
    const formName = document.getElementById('name')?.value;
    const formEmail = document.getElementById('email')?.value;
    const formSubject = document.getElementById('subject')?.value;
    const formMessage = document.getElementById('message')?.value || '';

    if (!(formName && formEmail && formSubject)) {
      return alert(
        'The email form is incomplete - Please fill out all required sections.'
      );
    }

    sendSupportEmailRequest(formName, formEmail, formSubject, formMessage);
  };

  return (
    <section id="contact" className="contact section-bg">
      <div className="container" data-aos="fade-up">
        <div className="section-title">
          <h2>Contact</h2>
          <p>
            Magnam dolores commodi suscipit. Necessitatibus eius consequatur ex
            aliquid fuga eum quidem. Sit sint consectetur velit. Quisquam quos
            quisquam cupiditate. Et nemo qui impedit suscipit alias ea. Quia
            fugiat sit in iste officiis commodi quidem hic quas.
          </p>
        </div>

        <div className="row">
          <div className="col-lg-6">
            <div className="info-box mb-4">
              <i className="bx bx-map" />
              <h3>Our Address</h3>
              <p>A108 Adam Street, New York, NY 535022</p>
            </div>
          </div>

          <div className="col-lg-3 col-md-6">
            <div className="info-box  mb-4">
              <i className="bx bx-envelope" />
              <h3>Email Us</h3>
              <p>contact@example.com</p>
            </div>
          </div>

          <div className="col-lg-3 col-md-6">
            <div className="info-box  mb-4">
              <i className="bx bx-phone-call" />
              <h3>Call Us</h3>
              <p>+1 5589 55488 55</p>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-lg-6 ">
            <iframe
              className="mb-4 mb-lg-0"
              id="about-map"
              src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d12097.433213460943!2d-74.0062269!3d40.7101282!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xb89d1fe6bc499443!2sDowntown+Conference+Center!5e0!3m2!1smk!2sbg!4v1539943755621"
              frameBorder="0"
              style={{ border: 0, width: '100%', height: 384 }}
              allowFullScreen
            />
          </div>

          <div className="col-lg-6">
            <form role="form" className="php-email-form">
              <div className="form-row">
                <div className="col-md-6 form-group">
                  <input
                    type="text"
                    name="name"
                    className="form-control"
                    id="name"
                    placeholder="Your Name"
                    data-rule="minlen:4"
                    data-msg="Please enter at least 4 chars"
                  />
                  <div className="validate" />
                </div>
                <div className="col-md-6 form-group">
                  <input
                    type="email"
                    className="form-control"
                    name="email"
                    id="email"
                    placeholder="Your Email"
                    data-rule="email"
                    data-msg="Please enter a valid email"
                  />
                  <div className="validate" />
                </div>
              </div>
              <div className="form-group">
                <input
                  type="text"
                  className="form-control"
                  name="subject"
                  id="subject"
                  placeholder="Subject"
                  data-rule="minlen:4"
                  data-msg="Please enter at least 8 chars of subject"
                />
                <div className="validate" />
              </div>
              <div className="form-group">
                <textarea
                  className="form-control"
                  name="message"
                  id="message"
                  rows={5}
                  data-rule="required"
                  data-msg="Please write something for us"
                  placeholder="Message"
                />
                <div className="validate" />
              </div>
              <div className="mb-3">
                <div className="loading">Loading</div>
                <div className="error-message" />
                <div className="sent-message">
                  Your message has been sent. Thank you!
                </div>
              </div>
              <div className="text-center">
                <button
                  id="send-email-button"
                  onClick={() => sendSupportRequestEmailAction()}
                  type="submit"
                >
                  Send Message
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Contact;
